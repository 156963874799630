<template>
  <div class="Detailpage">
    <div class="aaa">aaa</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.aaa {
    width: 100%;
    height: 100px;
    background: red;
}
.Detailpage {
  width: calc(100vw - 114px);
  height: calc(100vh - 192px);
  margin-left: 114px;
  margin-top: 192px;
  padding-right: 24px;
  box-sizing: border-box;
  overflow: overlay;
}
.Detailpage::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  cursor: pointer;
}

/* 滚动槽 */
.Detailpage::-webkit-scrollbar-track {
  border-radius: 6px;
  cursor: pointer;
}

/* 滚动条滑块 */
.Detailpage::-webkit-scrollbar-thumb {
  border-radius: 4px;
  cursor: pointer;
  background: #666;
  background: rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 3;
}

/* 滚动条滑块 */
.Detailpage::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
</style>